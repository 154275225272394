<template>
  <div>
    <div v-for="(file, index) in files" :key="index">
      <a @click.prevent="processDownload(file.url)" class="full-width button--large">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 24.20097 23.459"
          enable-background="new 0 0 24.20097 23.459"
          width="32"
          height="31"
          class="fileicon"
        >
          <path
            class="icon"
            d="M21.11097,4.13751L17.19106,0.2176C17.05153,0.07858,16.86264,0,16.66569,0H4.07987,C3.66985,0,3.33689,0.33296,3.33689,0.74298v21.97304c0,0.41002,0.33296,0.74298,0.74298,0.74298h16.50572,c0.41002,0,0.74298-0.33295,0.74298-0.74298V4.66289C21.32857,4.46593,21.24999,4.27704,21.11097,4.13751z M19.84261,4.97066V5.2608,H16.7896V1.91764L19.84261,4.97066z M4.82285,21.97304V1.48596h10.48079v4.51782c0,0.41002,0.33296,0.74298,0.74298,0.74298h3.79599,v15.22629H4.82285z"
          />
          <path
            class="bar"
            d="M22.82921,16.51408H1.37177c-0.73512-0.01321-1.32788-0.60597-1.34109-1.34109v-4.88698,c0.01321-0.73512,0.60597-1.32788,1.34109-1.34109h21.45744c0.73512,0.01321,1.32788,0.60597,1.34109,1.34109v4.88698,C24.15709,15.90811,23.56433,16.50087,22.82921,16.51408z"
          />
          <text class="text" x="13" y="12.5">
            {{ getExtension(file.file_name) }}
          </text>
        </svg>
        <span class="filename">{{ readableFileName(file.file_name) }} </span>
        <small class="filesize color--grey">{{
          formatBytes(file.file_size)
        }}</small>
        <i class="icon-download"></i>
      </a>
      <hr />
    </div>
    <div class="card__section">
      <p class="text-right">
        <span class="modal__close" @click="$emit('close')">{{
          $t("app.close")
        }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import backend from '@/backend'
import helpers from '@/helpers'

export default {
  name: 'AssetFileDownload',
  mixins: [helpers],
  props: {
    files: [Array, Object]
  },
  methods: {
    getExtension (data) {
      const ext = /^.+\.([^.]+)$/.exec(data)
      return ext == null ? '' : ext[1]
    },
    formatBytes (bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes'
      const k = 1000
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    },
    processDownload (e) {
      backend
        .get(
          `${process.env.VUE_APP_URL}/assets/${this.$route.params.pathName}/download`
        )
        .then(response => {
          window.open(e)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/init/variables/variables";
svg.fileicon {
  float: left;
  top: auto !important;

  .icon {
    fill: #bfbfbf;
  }

  .bar {
    fill: var(--primary-color);
  }

  .text {
    text-transform: uppercase;
    fill: #ffffff;
    text-anchor: middle;
    alignment-baseline: central;
    font-family: $body-font-family;
    font-size: 6.5px;
  }
}

a.button--large {
  .filename {
    display: block;
    width: calc(100% - 110px);
    padding-left: 20px;
    line-height: 2;
    top: rem-calc(2);
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    font-weight: 500;

    &:after {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      padding: 10px 0 10px 30px;
      font-weight: 500;
      background: white;
      background: linear-gradient(
        to right,
        rgba(#fff, 0) 0%,
        rgba(#fff, 1) 30%
      );
    }
  }
}
.filesize {
  right: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
hr {
  margin: 0;
}
</style>
