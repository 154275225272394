<template>
  <div v-if="asset">
    <portal to="pageHeaderLeft"></portal>
    <portal to="pageHeaderRight">
      <div v-if="asset">
        <span
          v-if="asset.files.length > 0"
          @click="downloadAssets(asset.files)"
          class="button-branded button-branded--download"
        >{{ $t('app.downloads') }} <i class="icon-icon-download-arrow"></i
        ></span>

        <contact-dropdown/>
        <share-dropdown
          :subjectType="`asset`"
          :subjectId="asset.id"
        ></share-dropdown>

        <router-button
          v-if="$store.state.is_brand_manager"
          cssClass="ab-button--white ab-button--edit"
          icon="icon-icon-edit-pencil-line"
          :text="$t('app.edit')"
          routeName="AssetEdit"
          :routeParam="asset.slug"
        />

        <delete-button
          @delete="removeAsset(asset.slug)"
          v-if="$store.state.is_brand_manager"
        />
      </div>
    </portal>

    <div class="grid-x grid-margin-x">
      <div class="cell medium-3">
        <card-item :title="$t('assets.preview')" :no-content-wrapper="true">
          <div class="asset-preview" @click="showSingle()">
            <img :src="asset.preview_image.url" alt="" class="full-width"/>
          </div>
        </card-item>
      </div>

      <div class="cell medium-6">
        <card-item :title="$t('app.title')">
          <h2 class="light">{{ asset.title }}</h2>
        </card-item>
        <div class="spacer--20"></div>

        <card-item :title="$t('assets.theme_or_campaign')">
          <p v-if="asset.campaign">{{ asset.campaign.name }}</p>
          <p v-else>-</p>
        </card-item>
        <div class="spacer--20"></div>

        <card-item :title="$t('assets.format')">
          <div class="grid-x format-table" v-if="asset.format_width && asset.format_height && asset.format_unit_id">
            <div class="cell shrink px-2 py-1 format-label"><p>{{$t('assets.width')}}</p></div>
            <div class="cell auto px-2 py-1 sizing"><p>{{ `${asset.format_width} ${asset.unit.name}` }}</p></div>
            <div class="cell shrink px-2 py-1 format-label"><p>{{$t('assets.height')}}</p></div>
            <div class="cell auto px-2 py-1 sizing"><p>{{ `${asset.format_height} ${asset.unit.name}` }}</p></div>
          </div>
          <p v-else>-</p>
        </card-item>

        <div class="spacer--20"></div>

        <card-item :title="$t('app.category')">
          <p>{{ asset.category.name }}</p>
        </card-item>
        <div class="spacer--20"></div>
        <card-item :title="$t('assets.channel')">
          <p v-if="asset.channel">
            {{ asset.channel.name }}
          </p>
        </card-item>
        <div class="spacer--20"></div>
        <card-item :title="$t('app.description')">
          <p v-if="asset.description" v-html="asset.description"></p>
          <p v-else>-</p>
        </card-item>
        <div class="spacer--20"></div>
        <card-item
          v-if="asset.brand_elements.length > 0"
          :title="$t('assets.brand_elements')"
        >
          <div
            class="
              grid-x grid-margin-x
              medium-up-2
              card-list card-list--small-margin
            "
          >
            <router-link
              v-for="(page, index) in asset.brand_elements"
              :key="index"
              :to="{
                name: 'BrandDetail',
                params: { pathName: page.slug },
              }"
              class="cell button--card has-icon has-border"
            >
              <i :class="page.icon"></i>
              {{ page.title }}
            </router-link>
          </div>
        </card-item>
        <div class="spacer--20"></div>
        <card-item :title="$t('app.language')">
          <p v-if="asset.language_label">{{ asset.language_label }}</p>
          <p v-else>-</p>
        </card-item>
      </div>

      <aside class="cell medium-3">
        <div class="card-download-list">
          <div class="card-download-list__title">
            <i class="icon-downloads"></i>
            <span class="text-uppercase">{{ $t('app.downloads') }}</span>
          </div>
          <div class="spacer--10"></div>
          <label class="card-download-list__list">
            <span v-for="(file, index) in asset.files" :key="index" class="">
              <download-button
                type="assets"
                cssClass="card-download-list__list__button"
                :url="file.url"
                :title="readableFileName(file.file_name)"
                :icon="true"
              >
              </download-button>
            </span>
          </label>
        </div>

        <div class="spacer--20"></div>

        <card-item v-if="asset.created_at" :title="$t('assets.uploaded_at')">
          <p>{{ asset.created_at | formatDate }}</p>
        </card-item>
        <div class="spacer--20"></div>
        <card-item
          :title="$t('assets.communication_date')"
          v-if="asset.date_from && asset.date_to"
        >
          <p>
            {{ asset.date_from | formatDate }} -
            {{ asset.date_to | formatDate }}
          </p>
        </card-item>
        <div class="spacer--20"></div>
        <card-item :title="$t('app.tags')" icon="icon-tags">
          <div class="tag__container" v-if="asset.tags.length > 0">
            <div v-for="(tag, index) in asset.tags" :key="index" class="tag">
              {{ tag.name }}
            </div>
          </div>
          <span v-else>
            <p>-</p>
          </span>
        </card-item>
        <div class="spacer--20"></div>
        <card-item
          v-if="asset.uploader"
          :title="$t('assets.uploaded_by')"
          icon="icon-menu-profile"
        >
          <profile-image
            :avatar="asset.uploader.avatar.url"
            :name="asset.uploader.name + ' ' + asset.uploader.surname"
          />
        </card-item>
      </aside>
    </div>
  </div>
</template>

<script>
import backend from '@/backend'
import moment from 'moment'
import lightboxModal from '@/views/common/components/elements/LightboxModal'
import assetFileDownload from '../components/AssetFileDownload'
import helpers from '@/helpers'

export default {
  name: 'AssetDetail',
  mixins: [helpers],
  data () {
    return {
      asset: null,
      index: 0 // default
    }
  },
  created () {
    backend
      .get(`${process.env.VUE_APP_URL}/assets/${this.$route.params.pathName}`)
      .then((response) => {
        this.asset = response.data
        document.title = 'ABRND | Assets - ' + response.data.title
      })
  },
  filters: {
    formatDate (value) {
      return moment(String(value)).format('D MMMM YYYY')
    }
  },
  methods: {
    removeAsset (path) {
      this.$modal.show('dialog', {
        title: '',
        text: this.$t('app.remove_item'),
        buttons: [
          {
            title: this.$t('app.cancel'),
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: this.$t('app.remove_item'),
            default: true,
            handler: () => {
              backend
                .delete(`${process.env.VUE_APP_URL}/assets/${path}`)
                .then(() => {
                  this.$notify({
                    group: 'custom-template',
                    title: false,
                    position: 'bottom left',
                    text: this.$t('assets.asset_removed')
                  })
                  this.$modal.hide('dialog')
                  this.$router.push({ name: 'AssetList' })
                })
            }
          }
        ]
      })
    },
    downloadAssets (files) {
      this.$modal.show(
        assetFileDownload,
        {
          files: files
        },
        {
          draggable: false,
          scrollable: true,
          height: 'auto',
          width: '600px'
        }
      )
    },
    showSingle () {
      this.$modal.show(
        lightboxModal,
        {
          item: this.asset
        },
        {
          draggable: false,
          scrollable: false,
          height: 'auto',
          minWidth: 200
        }
      )
    },
    processDownload (e) {
      backend
        .get(
          `${process.env.VUE_APP_URL}/assets/${this.$route.params.pathName}/download`
        )
        .then((response) => {
          window.open(e)
        })
    }
  }
}
</script>

<style lang="scss">
.vel-toolbar {
  display: none !important;
}
</style>
<style lang="scss" scoped>
.page__inner-content {
  > div {
    + div {
      margin-top: 40px;
    }
  }
}

.asset-preview {
  cursor: pointer;
}

.tag__container {
  .tag {
    font-size: $body-font-size;
  }
}

.format-table {
  .cell {
    border: 1px solid $light-gray;
    &:not(:first-child) {
      border-left: 0;
    }
    &:first-child {
      border-radius: 5px 0 0 5px;
    }
    &:last-child {
      border-radius: 0 5px 5px 0;
    }
    &.format-label {
      text-transform: uppercase;
      font-weight: 500;
      color: $light-gray;
    }
  }
}

.card-download-list {
  background: var(--button-primary-color);
  overflow: visible;
  padding: calc(30px);
  border-radius: $global-radius;
  color: var(--button-primary-text-color);

  i {
    position: absolute;
  }

  &__title {
    font-size: calc(14px);
    font-weight: 500;

    span {
      margin-left: 30px;
    }

    i {
      margin-top: -1px;
    }
  }

  &__list {
    :hover {
      background: var(--button-primary-text-color);
      color: var(--button-primary-color);
    }

    &__button {
      padding: 10px 42px 14px 15px;
      display: block;
      border-radius: $global-radius;
      border: solid 2px var(--button-primary-text-color);
      position: relative;
      height: auto;
      margin-bottom: calc(5px);
      font-weight: 500;
      font-size: rem-calc(14);
      text-decoration: none;
      color: var(--button-primary-text-color);
      line-height: 14px;
    }

    :before {
      top: calc(13px);
      width: calc(15px);
      height: calc(15px);
      position: absolute;
      right: calc(15px);
    }
  }
}
</style>
