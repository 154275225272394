<template>
  <div class="">
    <img :src="item.preview_image.url" alt="" />
  </div>
</template>

<script>
export default {
  name: 'LightboxModal',
  props: {
    item: Object
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
</style>
