import { render, staticRenderFns } from "./AssetDetail.vue?vue&type=template&id=106163c4&scoped=true"
import script from "./AssetDetail.vue?vue&type=script&lang=js"
export * from "./AssetDetail.vue?vue&type=script&lang=js"
import style0 from "./AssetDetail.vue?vue&type=style&index=0&id=106163c4&prod&lang=scss"
import style1 from "./AssetDetail.vue?vue&type=style&index=1&id=106163c4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "106163c4",
  null
  
)

export default component.exports